'use client'

import { useState } from 'react'
import dynamic from 'next/dynamic'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Grid2 as Grid } from '@mui/material'

// Types
import type { ReactNode } from 'react'
import type { SxProps } from '@mui/material'
import type { LatestNewsProps } from '@/types/components/organisms/sections/latest-news'

// Components
const ArticleItemMolecule = dynamic(() => import('@/components/molecules/items/article'))
const GrayButtonAtom = dynamic(() => import('@/components/atoms/buttons/text/gray'))

const LatestNewsOrganism = (props: LatestNewsProps) => {
	// Props
	const { items } = props

	// Variables
	const muiTheme = useTheme()
	const greaterThanMedium = useMediaQuery(muiTheme.breakpoints.up('md'))
	const [hoverItem, setHoverItem] = useState<number | null>(null)
	let containers: ReactNode[] = []

	const displayNone: SxProps = {
		height: 136,
		'& .article-title': {
			'& .MuiTypography-root ': {
				WebkitLineClamp: 1,
				lineClamp: 1
			}
		},
		'& .article-text, & .article-date': {
			display: 'none'
		},
		'& .article-button': {
			flexBasis: '50%',
			maxWidth: 0.5
		}
	}

	for (let i = 0; i < items.length; i += 2) {
		containers.push(
			<Grid
				container
				height={{ xs: hoverItem === i || (i === 0 && !greaterThanMedium) || hoverItem === i + 1 ? 445 : 320, md: 534 }}
				position="relative"
				sx={{
					...(greaterThanMedium && {
						'& > .MuiGrid2-root:first-child': {
							width: 1,
							position: 'absolute',
							top: 0,
							left: 0
						},
						'& > .MuiGrid2-root:last-child': {
							width: 1,
							position: 'absolute',
							bottom: 0,
							left: 0
						},
						...(hoverItem === i + 1 && { '& > .MuiGrid2-root:first-child > .MuiGrid2-root': displayNone }),
						...((hoverItem === i || (i === 0 && !greaterThanMedium)) && { '& > .MuiGrid2-root:last-child > .MuiGrid2-root': displayNone })
					})
				}}
			>
				<ArticleItemMolecule
					{...items[i]}
					hovered={hoverItem === i || (i === 0 && !greaterThanMedium)}
					onMouseEnter={() => {
						if (greaterThanMedium) setHoverItem(i)
					}}
					onMouseLeave={() => setHoverItem(null)}
				/>

				<ArticleItemMolecule
					{...items[i + 1]}
					hovered={hoverItem === i + 1}
					onMouseEnter={() => {
						if (greaterThanMedium) setHoverItem(i + 1)
					}}
					onMouseLeave={() => setHoverItem(null)}
				/>
			</Grid>
		)
	}

	return (
		<Grid size={12} mt={{ xs: 5, md: 7 }}>
			<Grid container justifyContent="flex-start" alignItems="stretch" spacing={{ md: 2 }}>
				{containers.map((container, index) => {
					return (
						<Grid key={index} size={{ xs: 12, md: 4 }}>
							{container}
						</Grid>
					)
				})}

				<Grid size={12} justifyContent="center" display={{ xs: 'flex', md: 'none' }} mt={5.25}>
					<GrayButtonAtom title="form:button.viewAll" />
				</Grid>
			</Grid>
		</Grid>
	)
}

export default LatestNewsOrganism

'use client'

import dynamic from 'next/dynamic'
import { Grid2 as Grid, Typography } from '@mui/material'

// Types
import type { CategoryItemProps } from '@/types/components/molecules/items/category'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Components
const RoundedButtonAtom = dynamic(() => import('@/components/atoms/buttons/text/rounded'))
const StripeItemShapeAtom = dynamic(() => import('@/components/atoms/shapes/stripe-item'))
const DividerAtom = dynamic(() => import('@/components/atoms/divider'))

const CategoryItemMolecule = (props: CategoryItemProps) => {
	// Props
	const { title, image, color, large } = props

	// Varaibles
	const { t } = useTranslation()

	return (
		<Grid container alignItems="center" height={{ xs: large ? 360 : 255, md: large ? 486 : 235 }} position="relative" bgcolor={color} borderRadius={3} overflow="hidden">
			<StripeItemShapeAtom />

			<Grid
				size={12}
				height={1}
				p={3}
				pb={10}
				zIndex={1}
				sx={{
					'& picture': {
						display: 'inline-flex',
						width: 1,
						height: 1,
						'& img': {
							width: 1,
							height: 1,
							objectFit: 'scale-down',
							objectPosition: 'center'
						}
					}
				}}
			>
				<picture>
					<img src={image} alt={t(title) ?? ''} />
				</picture>
			</Grid>

			<Grid size={12} position="absolute" bottom={21} left={0} zIndex={2}>
				<Grid container justifyContent="center" alignItems="center">
					<Grid size={12} display="flex" justifyContent="center">
						<RoundedButtonAtom title={title} />
					</Grid>

					<Grid size={12} mt={1} sx={{ '&.MuiGrid2-root': { maxWidth: 250 } }}>
						<DividerAtom>
							<Typography fontSize={14} letterSpacing={-0.4} mx={0.65}>
								{t('common:app.name')}
							</Typography>
						</DividerAtom>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default CategoryItemMolecule

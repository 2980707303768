import(/* webpackMode: "eager" */ "/home/kimia/components/atoms/typographies/subtitle.tsx");
;
import(/* webpackMode: "eager" */ "/home/kimia/components/atoms/typographies/title.tsx");
;
import(/* webpackMode: "eager" */ "/home/kimia/components/molecules/box/download-application.tsx");
;
import(/* webpackMode: "eager" */ "/home/kimia/components/molecules/items/category.tsx");
;
import(/* webpackMode: "eager" */ "/home/kimia/components/molecules/items/service.tsx");
;
import(/* webpackMode: "eager" */ "/home/kimia/components/organisms/sections/latest-news.tsx");
;
import(/* webpackMode: "eager" */ "/home/kimia/components/organisms/slider/carousel/comments.tsx");
;
import(/* webpackMode: "eager" */ "/home/kimia/components/organisms/slider/carousel/cutomers.tsx");
;
import(/* webpackMode: "eager" */ "/home/kimia/components/organisms/slider/carousel/products.tsx");
;
import(/* webpackMode: "eager" */ "/home/kimia/components/organisms/slider/slideshow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/kimia/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/kimia/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager" */ "/home/kimia/node_modules/@mui/material/Grid2/Grid2.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/kimia/node_modules/@mui/material/SvgIcon/SvgIcon.js");
;
import(/* webpackMode: "eager" */ "/home/kimia/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/kimia/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");

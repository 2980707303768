'use client'

import dynamic from 'next/dynamic'
import { dir } from 'i18next'
import { Grid2 as Grid, Box } from '@mui/material'
import { Spinorama, SpinoramaWrapper, SpinoramaItem, SpinoramaActions, SpinoramaNext, SpinoramaPrev, SpinoramaThumbnails, SpinoramaThumbnail } from 'spinorama'

// Types
import type { CommentsCarouselProps } from '@/types/components/organisms/slider/carousel/comments'

// Components
import ArrowRightButtonAtom from '@/components/atoms/buttons/icons/arrow-right'
import ArrowLeftButtonIconAtom from '@/components/atoms/buttons/icons/arrow-left'
import ThumbnailButtonIconAtom from '@/components/atoms/buttons/icons/thumbnail'

const CommentItemMolecule = dynamic(() => import('@/components/molecules/items/comment'))
const DividerAtom = dynamic(() => import('@/components/atoms/divider'))

const CommentsCarouselOrganism = (props: CommentsCarouselProps) => {
	// Props
	const { items } = props

	// Varaibles
	const lngDir = dir()

	return (
		<Grid size={12} mt={{ xs: 5, md: 7 }}>
			<Spinorama>
				<SpinoramaWrapper>
					{items.map((item, index) => (
						<SpinoramaItem key={index} size={{ xs: 9, md: 4 }}>
							<CommentItemMolecule {...item} />
						</SpinoramaItem>
					))}
				</SpinoramaWrapper>

				<SpinoramaPrev layout="bottom-left" display={{ xs: 'none', md: 'block' }}>
					<ArrowLeftButtonIconAtom
						sx={{
							transform: {
								xs: 'translateY(-50%)',
								lg: `translateX(${lngDir === 'rtl' ? '-50%' : '50%'}) translateY(-50%)`
							}
						}}
					/>
				</SpinoramaPrev>

				<SpinoramaNext layout="bottom-right" display={{ xs: 'none', md: 'block' }}>
					<ArrowRightButtonAtom
						sx={{
							transform: {
								xs: 'translateY(-50%)',
								lg: `translateX(${lngDir === 'rtl' ? '50%' : '-50%'}) translateY(-50%)`
							}
						}}
					/>
				</SpinoramaNext>

				<SpinoramaActions mt={4} pb={1.25} position="relative">
					<SpinoramaThumbnails justify="center" spacing={1}>
						{items.map((_, index) => (
							<SpinoramaThumbnail key={index} px={0.5}>
								<ThumbnailButtonIconAtom />
							</SpinoramaThumbnail>
						))}
					</SpinoramaThumbnails>

					<DividerAtom
						sx={{
							width: '80%',
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: `translate(${lngDir === 'rtl' ? '50%' : '-50%'}, calc(-50% - 5px))`,
							zIndex: -1
						}}
					>
						<Box width={items.length * 44} p={0.5} />
					</DividerAtom>
				</SpinoramaActions>
			</Spinorama>
		</Grid>
	)
}

export default CommentsCarouselOrganism

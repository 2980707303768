'use client'

import { Typography } from '@mui/material'

// Types
import type { TypographiesProps } from '@/types/components/atoms/typographies'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

const TitleTypographyAtom = (props: TypographiesProps) => {
	// Props
	const { text } = props

	// Varaibles
	const { t } = useTranslation()

	return (
		<Typography variant="h3" fontSize={{ xs: 24, md: 40 }} fontWeight={700} lineHeight={1.5} letterSpacing={-2} textAlign="center">
			{t(text)}
		</Typography>
	)
}

export default TitleTypographyAtom

'use client'

import dynamic from 'next/dynamic'
import Link from 'next/link'
import Image from 'next/image'
import { dir } from 'i18next'
import { alpha } from '@mui/material/styles'
import { Grid2 as Grid, Box, Container, Typography } from '@mui/material'
import { Apple as AppleIcon } from '@mui/icons-material'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Components
const RatingAtom = dynamic(() => import('@/components/atoms/rating'))
const DownloadedAtom = dynamic(() => import('@/components/atoms/downloaded'))
const BlackButtonAtom = dynamic(() => import('@/components/atoms/buttons/text/black'))
const PlayStoreIconAtom = dynamic(() => import('@/components/atoms/icons/play-store'))
const WavyArrowShapeAtom = dynamic(() => import('@/components/atoms/shapes/wavy-arrow'))

const DownloadApplicationMolecule = () => {
	// Varaibles
	const { t } = useTranslation()

	// Varaibles
	const lngDir = dir()

	return (
		<Grid size={12} position="relative" bgcolor="#fafafa" overflow="hidden">
			<Grid container justifyContent="center">
				<Grid
					mt={{ xs: 25, md: 15 }}
					mb={{ xs: 25, md: 'inherit' }}
					position="relative"
					sx={{
						width: { xs: 282, md: 608 },
						height: { xs: 426, md: 575 },
						'&::before': {
							content: "''",
							opacity: 0.49,
							width: { xs: 523, md: 1185 },
							height: { xs: 523, md: 1185 },
							position: 'absolute',
							top: '50%',
							left: '50%',
							backgroundImage: `radial-gradient(50% 50% at 50% 50%, #ba85ff 0%, ${alpha('#fff', 0)} 100%)`,
							transform: { xs: 'translate(-50%, -50%)', md: 'translate(-50%, -30%)' }
						},
						'& img': {
							width: 1,
							height: 1,
							position: 'absolute',
							top: 0,
							left: 0,
							objectPosition: 'top',
							objectFit: 'cover'
						}
					}}
				>
					<Image src="/images/parts/phones.png" alt="phones" width={608} height={920} />
				</Grid>
			</Grid>

			<Box width={1} position="absolute" top={{ xs: 27, md: 58 }} left={0} zIndex={1}>
				<Container>
					<Grid container>
						<Grid size={{ xs: 10.5, md: 5 }}>
							<Typography variant="h3" component="h3" fontSize={{ xs: 32, md: 40 }} fontWeight={700} lineHeight={1.5} letterSpacing={{ xs: -1, md: -1 }}>
								{t('common:title.downloadApplication')}
							</Typography>
						</Grid>
					</Grid>
				</Container>
			</Box>

			<Box
				position="absolute"
				top={{ xs: 200, md: 96 }}
				zIndex={1}
				sx={{
					...(lngDir === 'rtl' ? { right: '50%' } : { left: '50%' }),
					transform: {
						xs: `translateX(${lngDir === 'rtl' ? '140px' : '-140px'})`,
						md: `translateX(${lngDir === 'rtl' ? '460px' : '-460px'})`,
						lg: `translateX(${lngDir === 'rtl' ? '570px' : '-570px'})`
					}
				}}
			>
				<RatingAtom />
			</Box>

			<Box
				position="absolute"
				bottom={{ xs: 210, md: 92 }}
				zIndex={1}
				sx={{
					...(lngDir === 'rtl' ? { right: '50%' } : { left: '50%' }),
					transform: {
						xs: `translateX(${lngDir === 'rtl' ? '-20px' : '20px'})`,
						md: `translateX(${lngDir === 'rtl' ? '-170px' : '170px'})`,
						lg: `translateX(${lngDir === 'rtl' ? '-290px' : '290px'})`,
						xl: `translateX(${lngDir === 'rtl' ? '-320px' : '320px'})`
					}
				}}
			>
				<DownloadedAtom />
			</Box>

			<Box
				width={{ xs: 77.5, md: 120 }}
				height={{ xs: 50, md: 80 }}
				position="absolute"
				bottom={{ xs: 180, md: 260 }}
				zIndex={1}
				sx={{
					...(lngDir === 'rtl' ? { right: '50%' } : { left: '50%' }),
					transform: {
						xs: `translateX(${lngDir === 'rtl' ? '70px' : '-70px'})`,
						md: `translateX(${lngDir === 'rtl' ? '380px' : '-380px'})`
					}
				}}
			>
				<WavyArrowShapeAtom />
			</Box>

			<Grid
				container
				flexDirection="column"
				spacing={1.5}
				width="auto"
				position="absolute"
				bottom={{ xs: 51, md: 41 }}
				zIndex={1}
				sx={{
					...(lngDir === 'rtl' ? { right: '50%' } : { left: '50%' }),
					transform: {
						xs: `translateX(${lngDir === 'rtl' ? '50%' : '-50%'})`,
						md: `translateX(${lngDir === 'rtl' ? '240%' : '-240%'})`,
						lg: `translateX(${lngDir === 'rtl' ? '300%' : '-300%'})`
					}
				}}
			>
				<Grid>
					<Link href="/">
						<BlackButtonAtom title="form:button.android" startIcon={<PlayStoreIconAtom />} />
					</Link>
				</Grid>

				<Grid>
					<Link href="/">
						<BlackButtonAtom title="form:button.ios" startIcon={<AppleIcon />} />
					</Link>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default DownloadApplicationMolecule

'use client'

import dynamic from 'next/dynamic'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery, Grid2 as Grid, Typography } from '@mui/material'
import { KeyboardBackspaceRounded as KeyboardBackspaceRoundedIcon } from '@mui/icons-material'

// Types
import type { ServiceItemProps } from '@/types/components/molecules/items/service'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Components
const TextButtonAtom = dynamic(() => import('@/components/atoms/buttons/text/index'))

const ServiceItemMolecule = (props: ServiceItemProps) => {
	// Props
	const { title, subtitle, icon, color, iconColor } = props

	// Varaibles
	const { t } = useTranslation()
	const muiTheme = useTheme()
	const greaterThanMedium = useMediaQuery(muiTheme.breakpoints.up('md'))

	return (
		<Grid container height={1} pt={{ xs: 2, md: 6.25 }} pl={{ xs: 2.125, md: 1.75 }} pr={{ xs: 4, md: 3 }} pb={{ xs: 2.5, md: 3 }} bgcolor="#fafafa" borderRadius={3}>
			<Grid
				size={{ xs: 'auto', md: 12 }}
				sx={{
					'& .MuiSvgIcon-root': {
						width: { xs: 32, md: 40 },
						height: { xs: 32, md: 40 },
						color,
						'& .icon-color': { stroke: iconColor }
					}
				}}
			>
				{icon}
			</Grid>

			<Grid size={{ xs: 10, md: 12 }} ml={1}>
				<Typography variant="h5" fontWeight={700} fontSize={20} letterSpacing={-0.2}>
					{t(title)}
				</Typography>
			</Grid>

			<Grid size={{ xs: 10.5, md: 11 }}>
				<Typography variant="h5" fontWeight={400} fontSize={12} letterSpacing={-0.2}>
					{t(subtitle)}
				</Typography>
			</Grid>

			<Grid size={{ xs: 1, md: 12 }} color={color} mt={{ xs: 'inherit', md: 3.5 }} textAlign="right">
				<TextButtonAtom title={greaterThanMedium ? 'common:title.moreinformation' : ''} endIcon={<KeyboardBackspaceRoundedIcon />} />
			</Grid>
		</Grid>
	)
}

export default ServiceItemMolecule

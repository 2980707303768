'use client'

import { Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'

// Types
import type { TypographiesProps } from '@/types/components/atoms/typographies'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

const SubtitleTypographyAtom = (props: TypographiesProps) => {
	// Props
	const { text } = props

	// Varaibles
	const { t } = useTranslation()

	return (
		<Typography variant="h4" component="h4" color={alpha('#000', 0.8)} fontSize={{ xs: 14, md: 16 }} fontWeight={400} lineHeight={1.5} letterSpacing={-0.5} textAlign="center">
			{t(text)}
		</Typography>
	)
}

export default SubtitleTypographyAtom

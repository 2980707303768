'use client'

import { forwardRef } from 'react'
import { alpha } from '@mui/material/styles'
import { dir } from 'i18next'
import { IconButton } from '@mui/material'
import { EastRounded as EastRoundedIcon, WestRounded as WestRoundedIcon } from '@mui/icons-material'

// Types
import type { IconButtonProps } from '@/types/components/atoms/buttons/icon'

const ArrowLeftButtonIconAtom = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
	// Props
	const { onClick, sx } = props

	// Varaibles
	const lngDir = dir()

	return (
		<IconButton
			ref={ref}
			aria-label="arrow left icon button"
			onClick={onClick}
			sx={{
				p: 1.75,
				color: 'primary.contrastText',
				bgcolor: 'tertiary.contrastText',
				borderTopRightRadius: 0,
				borderTopLeftRadius: 32,
				borderBottomRightRadius: 0,
				borderBottomLeftRadius: 32,
				backdropFilter: 'blur(20px)',
				'&:hover': {
					color: 'tertiary.contrastText',
					bgcolor: alpha('#000', 0.07)
				},
				'& .MuiSvgIcon-root': { fontSize: 20 },
				'& .MuiTouchRipple-root .MuiTouchRipple-child': { borderRadius: 1 },
				...sx
			}}
		>
			{lngDir === 'rtl' ? <EastRoundedIcon /> : <WestRoundedIcon />}
		</IconButton>
	)
})

ArrowLeftButtonIconAtom.displayName = 'ArrowLeftButtonIconAtom'

export default ArrowLeftButtonIconAtom

'use client'

import { forwardRef } from 'react'
import { alpha } from '@mui/material/styles'
import { IconButton } from '@mui/material'

// Types
import type { IconButtonProps } from '@/types/components/atoms/buttons/icon'

const GlassIconButtonAtom = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
	// Props
	const { className, icon, onClick, sx } = props

	return (
		<IconButton
			ref={ref}
			aria-label="glass icon button"
			className={className}
			onClick={onClick}
			sx={{
				p: 2,
				color: { xs: 'common.white', md: 'common.black' },
				bgcolor: { xs: alpha('#000', 0.87), md: alpha('#fff', 0.17) },
				backdropFilter: 'blur(20px)',
				'&:hover': {
					color: { xs: 'common.white', md: 'common.black' },
					bgcolor: { xs: alpha('#000', 0.87), md: alpha('#fff', 0.17) }
				},
				'& .MuiSvgIcon-root': { fontSize: 24 },
				...sx
			}}
		>
			{icon}
		</IconButton>
	)
})

GlassIconButtonAtom.displayName = 'GlassIconButtonAtom'

export default GlassIconButtonAtom

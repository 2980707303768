'use client'

import { forwardRef } from 'react'
import { IconButton } from '@mui/material'
import { Circle as CircleIcon } from '@mui/icons-material'

// Types
import type { IconButtonProps } from '@/types/components/atoms/buttons/icon'

const ThumbnailButtonIconAtom = forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
	// Props
	const { onClick } = props

	return (
		<IconButton
			ref={ref}
			aria-label="thumbnail button"
			onClick={onClick}
			sx={{
				p: 0.75,
				fontSize: { xs: 12, md: 14 },
				color: '#d9d9d9',
				'&::before': {
					content: "''",
					width: 1,
					height: 1,
					position: 'absolute',
					top: 0,
					left: 0,
					border: `2px solid transparent`,
					borderRadius: 2,
					transition: 'all .3s ease'
				},
				'.selected &.MuiButtonBase-root': {
					color: 'primary.dark',
					'&::before': { borderColor: 'primary.dark' }
				},
				'&:hover': {
					color: 'primary.main',
					'&::before': { borderColor: 'primary.main' }
				}
			}}
		>
			<CircleIcon fontSize="inherit" />
		</IconButton>
	)
})

ThumbnailButtonIconAtom.displayName = 'ThumbnailButtonIconAtom'

export default ThumbnailButtonIconAtom

'use client'

import dynamic from 'next/dynamic'
import { dir } from 'i18next'
import { Grid2 as Grid, Typography } from '@mui/material'
import { Spinorama, SpinoramaWrapper, SpinoramaItem, SpinoramaNext, SpinoramaPrev } from 'spinorama'
import { East as EastIcon, West as WestIcon } from '@mui/icons-material'

// Types
import type { ProductsCarouselProps } from '@/types/components/organisms/slider/carousel/products'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

// Components
import GlassIconButtonAtom from '@/components/atoms/buttons/icons/glass'

const ProductItemMolecule = dynamic(() => import('@/components/molecules/items/product'))
const TextButtonAtom = dynamic(() => import('@/components/atoms/buttons/text'))

const ProductsCarouselOrganism = (props: ProductsCarouselProps) => {
	// Props
	const { title, items } = props

	// Varaibles
	const lngDir = dir()
	const { t } = useTranslation()

	return (
		<Grid size={12} mt={{ xs: 5, md: 3.5 }} mb={{ xs: 6, md: 3.5 }}>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid>
					{title && (
						<Typography variant="h4" fontSize={{ xs: 16, md: 20 }} fontWeight={600} lineHeight={1.5} letterSpacing={-0.4}>
							{t(title)}
						</Typography>
					)}
				</Grid>

				<Grid sx={{ color: '#004a73' }}>
					<TextButtonAtom title="form:button.viewAll" />
				</Grid>
			</Grid>

			<Spinorama interval={7500} ease="power4.inOut" mt={1.5} pb={{ xs: 6, md: 'inherit' }}>
				<SpinoramaWrapper>
					{items.map((item, index) => (
						<SpinoramaItem key={index} size={{ xs: 12, sm: 6, md: 4, lg: 3.5 }}>
							<ProductItemMolecule {...item} />
						</SpinoramaItem>
					))}
				</SpinoramaWrapper>

				<SpinoramaPrev layout={{ xs: 'bottom-center', md: 'center-left' }} showOnHover>
					<GlassIconButtonAtom icon={lngDir === 'rtl' ? <EastIcon /> : <WestIcon />} sx={{ transform: { lg: `translateX(${lngDir === 'rtl' ? '-50%' : '50%'})` } }} />
				</SpinoramaPrev>

				<SpinoramaNext layout={{ xs: 'bottom-center', md: 'center-right' }} showOnHover>
					<GlassIconButtonAtom icon={lngDir === 'rtl' ? <WestIcon /> : <EastIcon />} sx={{ transform: { lg: `translateX(${lngDir === 'rtl' ? '50%' : '-50%'})` } }} />
				</SpinoramaNext>
			</Spinorama>
		</Grid>
	)
}

export default ProductsCarouselOrganism

'use client'

import dynamic from 'next/dynamic'
import { dir } from 'i18next'
import { Grid2 as Grid, Container } from '@mui/material'
import { Spinorama, SpinoramaWrapper, SpinoramaItem, SpinoramaPrev, SpinoramaNext } from 'spinorama'
import { East as EastIcon, West as WestIcon } from '@mui/icons-material'

// Types
import type { CustomersCarouselProps } from '@/types/components/organisms/slider/carousel/customers'

// Components
import GlassIconButtonAtom from '@/components/atoms/buttons/icons/glass'

const CustomerItemMolecule = dynamic(() => import('@/components/molecules/items/customer'))

const CustomersCarouselOrganism = (props: CustomersCarouselProps) => {
	// Props
	const { items } = props

	// Varaibles
	const lngDir = dir()

	return (
		<Grid size={12} mt={{ xs: 5, md: 7 }}>
			<Container sx={{ px: { md: 12 } }}>
				<Spinorama interval={5000} duration={500} ease="back">
					<SpinoramaWrapper>
						{items.map((item, index) => (
							<SpinoramaItem key={index} size={{ xs: 9, sm: 5 }} maxWidth={{ md: 185 }}>
								<CustomerItemMolecule {...item} />
							</SpinoramaItem>
						))}
					</SpinoramaWrapper>

					<SpinoramaPrev layout="center-left" display={{ xs: 'none', md: 'block' }}>
						<GlassIconButtonAtom icon={lngDir === 'rtl' ? <EastIcon /> : <WestIcon />} sx={{ transform: { lg: `translateX(${lngDir === 'rtl' ? '-50%' : '50%'})` } }} />
					</SpinoramaPrev>

					<SpinoramaNext layout="center-right" display={{ xs: 'none', md: 'block' }}>
						<GlassIconButtonAtom icon={lngDir === 'rtl' ? <WestIcon /> : <EastIcon />} sx={{ transform: { lg: `translateX(${lngDir === 'rtl' ? '50%' : '-50%'})` } }} />
					</SpinoramaNext>
				</Spinorama>
			</Container>
		</Grid>
	)
}

export default CustomersCarouselOrganism

'use client'

import dynamic from 'next/dynamic'
import { dir } from 'i18next'
import { Container, Grid2 as Grid } from '@mui/material'
import { Spinorama, SpinoramaWrapper, SpinoramaPrev, SpinoramaNext, SpinoramaThumbnails, SpinoramaThumbnail } from 'spinorama'
import { East as EastIcon, West as WestIcon } from '@mui/icons-material'

// Types
import type { SlideshowProps } from '@/types/components/organisms/slider/slideshow'

// Components
import GlassIconButtonAtom from '@/components/atoms/buttons/icons/glass'
import ThumbnailButtonIconAtom from '@/components/atoms/buttons/icons/thumbnail'

const SlideshowItemMolecule = dynamic(() => import('@/components/molecules/items/slideshow'))
const StripeShapeAtom = dynamic(() => import('@/components/atoms/shapes/stripe'))

const SlideshowOrganism = (props: SlideshowProps) => {
	// Props
	const { items } = props

	// Varaibles
	const lngDir = dir()

	return (
		<Grid size={12} mt={{ xs: 3, md: 5 }} position="relative">
			<StripeShapeAtom />

			<Container>
				<Spinorama>
					<SpinoramaWrapper>
						{items.map((item, index) => (
							<SlideshowItemMolecule key={index} {...item} />
						))}
					</SpinoramaWrapper>

					<SpinoramaPrev layout="center-left" showOnHover display={{ xs: 'none', md: 'block' }}>
						<GlassIconButtonAtom icon={lngDir === 'rtl' ? <EastIcon /> : <WestIcon />} sx={{ transform: { lg: `translateX(${lngDir === 'rtl' ? '-50%' : '50%'})` } }} />
					</SpinoramaPrev>

					<SpinoramaNext layout="center-right" showOnHover display={{ xs: 'none', md: 'block' }}>
						<GlassIconButtonAtom icon={lngDir === 'rtl' ? <WestIcon /> : <EastIcon />} sx={{ transform: { lg: `translateX(${lngDir === 'rtl' ? '50%' : '-50%'})` } }} />
					</SpinoramaNext>

					<SpinoramaThumbnails
						spacing={1}
						sx={{
							width: { xs: '100%', sm: 'auto' },
							position: 'absolute',
							top: { xs: 'calc(100dvw - 10px)', sm: 'inherit' },
							right: { sm: '21%' },
							bottom: { sm: -24 },
							left: { xs: 0, sm: 'inherit' },
							transform: { sm: `translate(${lngDir === 'rtl' ? '50%' : '-50%'}, -50%)` },
							zIndex: 1
						}}
					>
						{items.map((_, index) => (
							<SpinoramaThumbnail key={index}>
								<ThumbnailButtonIconAtom />
							</SpinoramaThumbnail>
						))}
					</SpinoramaThumbnails>
				</Spinorama>
			</Container>
		</Grid>
	)
}

export default SlideshowOrganism
